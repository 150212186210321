@use '../abstracts' as esc;

.link {
    position: relative;
    @include esc.apply-typography('body');
    color: esc.color('primary-500');
    transition: color .2s ease-in-out;
    display: inline-flex;
    gap: 8px;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;

        opacity: 0;

        background-color: esc.color('primary-500');
    }

    &.is-tonal {
        color: esc.color('grey-700');

        &::after {
            background-color: esc.color('grey-700');
        }

        &:hover {
            color: esc.color('grey-800');

            &::after {
                background-color: esc.color('grey-800');
            }
        }

        &:active {
            color: esc.color('grey-900');

            &::after {
                background-color: esc.color('grey-900');
            }
        }

    }

    &:hover {
        color: esc.color('primary-500');

        &::after {
            background-color: esc.color('primary-500');
            opacity: 1;

        }
    }

    &:active {
        color: esc.color('primary-700');

        &::after {
            background-color: esc.color('primary-700');
            opacity: 1;
        }
    }

    &.is-underline {
        &::after {
            opacity: 1;
        }
    }

    &.is-small {
        @include esc.apply-typography('body-small');
        gap: 4px;
    }

    &.is-large {
        @include esc.apply-typography('body-large');
    }

}
