﻿@use '../abstracts' as esc;

@mixin add-badge-type($type) {
    &.is-#{$type} {
        background-color: esc.color('#{$type}-50');
        color: esc.color('#{$type}-700');

        .icon {
            fill: esc.color('#{$type}-500');
        }

        .esc-icon {
            color: esc.color('#{$type}-500');
        }

        &.is-with-dot,
        &.is-dot-only {
            &::before {
                background-color: esc.color('#{$type}-500');
            }
        }

        .esc-badge__dot {
            background-color: esc.color('#{$type}-500');
        }

        &.is-with-loader,
        &.is-loader-only {
            &::after {
                border-color: esc.color('#{$type}-500');
                border-bottom-color: transparent;
                border-left-color: transparent;
            }
        }
    }
}

.badge,
.esc-badge {
    display: flex;
    align-items: center;
    gap: 8px;

    position: relative;
    height: 24px;
    min-width: 24px;
    padding: 2px 8px 2px 8px;

    background-color: esc.color('grey-100');
    border-radius: 16px;
    color: esc.color('grey-900');

    @include esc.apply-typography('body-small');
    cursor: inherit;

    .icon {
        fill: esc.color('grey-500');
    }

    .esc-icon {
        color: esc.color('grey-500');
    }

    &__dot {
        margin: 4px;
        width: 8px;
        height: 8px;
        background-color: esc.color('grey-700');
        border-radius: 100%;
    }

    &__loader {
        display: block;

        width: 16px;
        height: 16px;

        border: 2px solid esc.color('grey-400');
        border-radius: 50%;
        border-bottom-color: transparent;
        border-left-color: transparent;
        animation: spin 0.8s infinite linear;
    }

    &.is-with-dot,
    &.is-dot-only {
        &::before {
            content: '';

            margin: 4px;
            width: 8px;
            height: 8px;
            background-color: esc.color('grey-700');
            border-radius: 100%;
        }
    }

    &.is-with-loader,
    &.is-loader-only {
        padding: 4px 8px 4px 24px;

        &::after {
            content: '';
            position: absolute;
            display: block;
            top: 7px;
            left: 7px;

            width: 12px;
            height: 12px;

            border: 2px solid esc.color('grey-400');
            border-radius: 50%;
            border-bottom-color: transparent;
            border-left-color: transparent;
            animation: spin 0.8s infinite linear;
        }
    }

    &.is-dot-only {
        padding: 4px 0 4px 26px;
    }

    &.is-loader-only {
        padding: 4px 0 4px 24px;
    }

    &.is-icon-only {
        padding: 4px 0 4px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 24px;
    }

    &.is-large {
        //@include esc.apply-typography('body-large');
        height: 32px;
        padding-inline: 12px;

    }

    @include add-badge-type('success');
    @include add-badge-type('info');
    @include add-badge-type('error');
    @include add-badge-type('warning');
    @include add-badge-type('purple');
}

.esc-small-badge {
    display: flex;
    height: 18px;
    padding: 2px 4px;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: esc.color('grey-500');

    color: esc.color('white');
    @include esc.apply-typography('body-extra-small-strong');

    &.is-primary {
        background-color: esc.color('primary-500');
    }
}
