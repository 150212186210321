.ess-legend-container {

}

.ess-legend-list {
    display: flex;
    gap: 16px;
}

.ess-legend-item {
    display: flex;

    cursor: pointer;

    font-size: 14px;
    line-height: 16px;
    align-items: center;
    gap: 4px;

    &__icon {
        width: 16px;
        height: 16px;

        mask-size: 16px 16px;
        mask-repeat: no-repeat;
        mask-position: center center;
        transition: background-color 200ms;
        background-color: #ff0000;

        &.is-circle {
            mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2IiBmaWxsPSJub25lIj4KICA8Y2lyY2xlIGN4PSI4IiBjeT0iOCIgcj0iNCIgZmlsbD0iY3VycmVudENvbG9yIi8+Cjwvc3ZnPg==");
        }

        &.is-triangle {
            mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2IiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNNy4xMzM5NyA0QzcuNTE4ODcgMy4zMzMzMyA4LjQ4MTEzIDMuMzMzMzMgOC44NjYwMyA0TDEyLjc2MzEgMTAuNzVDMTMuMTQ4IDExLjQxNjcgMTIuNjY2OSAxMi4yNSAxMS44OTcxIDEyLjI1SDQuMTAyODlDMy4zMzMwOSAxMi4yNSAyLjg1MTk2IDExLjQxNjcgMy4yMzY4NiAxMC43NUw3LjEzMzk3IDRaIiBmaWxsPSIjQUY3MEUyIi8+Cjwvc3ZnPg==");
        }

        &.is-diamond {
            mask-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuMjkyODkgMS4yMDcxMUM1LjY4MzQyIDAuODE2NTgzIDYuMzE2NTggMC44MTY1ODIgNi43MDcxMSAxLjIwNzExTDEwLjc5MjkgNS4yOTI4OUMxMS4xODM0IDUuNjgzNDIgMTEuMTgzNCA2LjMxNjU4IDEwLjc5MjkgNi43MDcxMUw2LjcwNzExIDEwLjc5MjlDNi4zMTY1OCAxMS4xODM0IDUuNjgzNDIgMTEuMTgzNCA1LjI5Mjg5IDEwLjc5MjlMMS4yMDcxMSA2LjcwNzExQzAuODE2NTgzIDYuMzE2NTggMC44MTY1ODIgNS42ODM0MiAxLjIwNzExIDUuMjkyODlMNS4yOTI4OSAxLjIwNzExWiIgZmlsbD0iIzQyQTRGRiIvPgo8L3N2Zz4=')
        }
    }

}
