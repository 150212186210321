﻿@use '../abstracts' as esc;


.esc-counter-badge {
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    height: 20px;
    width: 20px;

    background-color: esc.color('white');
    border: 1px solid esc.color('grey-500');
    border-radius: 50%;
    color: esc.color('grey-900');

    @include esc.apply-typography('body-extra-small');
    cursor: default;

    &.is-hidden {
        opacity: 0;
    }
}

.esc-counter {
    display: flex;
    min-width: auto;
    padding: 2px 4px;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 4px;
    background-color: esc.color('grey-500');
    height: 18px;

    @include esc.apply-typography('body-extra-small');
    font-weight: 500;
    color: esc.color('white');
}
