@use '../abstracts' as esc;

.esc-modal {
    position: relative;
    background: esc.color('white');
    overflow: initial;
    max-width: inherit;

    width: inherit;

    max-height: inherit;
    border-radius: 4px;
    border: 1px solid esc.color('grey-200');

    &.is-confirm-modal {
        .esc-modal__close {
            top: 22px;
            right: 16px;
        }
    }

    &__container {
        max-height: inherit;
    }

    &__header {
        &:empty {
            display: none;
        }

        padding: esc.separator('medium') esc.separator('medium') esc.separator('default') esc.separator('medium');
        border-bottom: 1px solid esc.color('grey-200');
    }

    &__content {
        overflow-y: auto;
        padding: esc.separator('medium');
        @include esc.scrollbar();
    }

    &__footer {
        padding: esc.separator('default') esc.separator('medium');
        border-top: 1px solid esc.color('grey-200');
    }

    &__host {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        max-height: inherit;
        width: 560px;
    }

    &__close {
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 22px;
        right: 24px;
        width: 24px;
        height: 24px;
        z-index: 2;

        &.esc-button.is-shadowed {
            padding: 0;
        }

        cursor: pointer;

        @include esc.media('md') {
            top: 28px;
        }
    }

    &__loading {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        z-index: 108;

        gap: 16px;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity .2s;
        background-color: esc.color('white');
        content: '';
        z-index: 107;
    }

    &.is-position-right {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.consents-overlay-panel {
    padding: esc.separator('default');
}

.esc-modal-overlay {
    max-height: 100vh;

    &.is-initialized {
        transition:
            opacity .2s ease-in-out,
            transform .2s ease-in-out;
    }

    &.is-position-center {
        max-height: calc(100dvh - 130px);
        opacity: 0;
    }

    &.is-position-bottom {
        opacity: 1;
        transform: translateY(100%);
        max-height: calc(100dvh - 65px);
        //height: 100%;
    }

    &.is-position-right {
        opacity: 1;
        max-height: 100vh;
        height: 100vh;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        transform: translateX(100%);
    }

    &.is-position-left {
        opacity: 1;
        max-height: 100vh;
        height: 100vh;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        transform: translateX(-100%);
    }

    &.is-opening {
        opacity: 1;
        transform: translateY(0) translateX(0);
    }

    &.is-position-right,
    &.is-position-left {
        .esc-modal__content {
            flex-grow: 1;
        }

        .esc-modal,
        .esc-modal__host,
        .esc-modal__container {
            height: inherit;
        }
    }

    &.is-position-bottom {
        .esc-modal__host,
        .esc-modal__container {
            height: 100%;
        }
    }
}
