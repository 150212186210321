@use '../abstracts' as esc;

input[type='email'],
input[type='number'],
input[type='password'],
input[type='tel'],
input[type='text'],
textarea {
    @include esc.apply-typography('body-small');
    // font-size: 16px;
    width: 100%;
    font-weight: 400;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    height: auto;
    padding: 0;
    line-height: 1;
    color: esc.color('grey-900');
    box-shadow: none;
    caret-color: esc.color('grey-900');
    flex-grow: 1;

    &::placeholder {
        color: esc.color('grey-500') !important;
    }

    &:read-only {
        cursor: default;
    }

    &:disabled,
    &.is-disabled {
        cursor: not-allowed;
    }
}

input:-webkit-autofill {
    -webkit-background-clip: text;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
