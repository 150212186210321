@use "early-stage-components/styles/abstracts" as esc;


.esc-form-field {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    gap: esc.separator('small');
    width: 100%;
}

.esc-form-field-title {
    font-weight: 500;
}

.esc-form-field-sub-title {
    @include esc.apply-typography('body-small');
    color: esc.color('grey-500');
}

.esc-form-field-description {
    @include esc.apply-typography('body-extra-small');
}

.esc-form-field-header {
    &:empty {
        display: none;
    }
}

.esc-form-field-content {
    &:empty {
        display: none;
    }

    display: flex;
    flex-direction: column;

    & > * {
        min-width: 0;
    }
}

.esc-form-field-input {
    display: flex;
    align-items: flex-start;

    &__suffix,
    &__prefix {
        &:empty {
            display: none;
        }

        display: flex;
        align-items: center;
        gap: esc.separator('tiny');
        color: esc.color('grey-700');
        background-color: esc.color('grey-80');

        border-radius: esc.separator('tiny') 0 0 esc.separator('tiny');
        padding: 0 esc.separator('default');
        border: 1px solid esc.color('grey-300');
        border-right-width: 0;

        height: 40px;

        transition: border-color .2s ease-in-out;
        cursor: default;

        @include esc.apply-typography('body-small');
        white-space: nowrap;

    }

    &__suffix {
        border-right-width: 1px;
        border-left-width: 0;
        border-radius: 0 esc.separator('tiny') esc.separator('tiny') 0;
    }

    &.is-show-invalid-state.is-invalid {
        .esc-form-field-input-item {
            border-color: esc.color('error-700');
            background-color: esc.color('error-50');
        }
    }

    &.is-disabled {
        .esc-form-field-input-item {
            cursor: not-allowed;
            background-color: esc.color('grey-80');


            &:hover {
                cursor: not-allowed;

                border-color: esc.color('grey-300');
            }
        }
    }

    &.is-readonly,
    &.is-datepicker {
        .esc-form-field-input-item {
            cursor: default;

            input {
                cursor: default;
            }
        }
    }

}

.esc-form-field-input-item {
    background-color: esc.color('white');
    cursor: text;

    display: flex;
    min-width: 0;
    align-items: center;
    flex-grow: 1;
    gap: 8px;
    min-height: 40px;
    padding: 0 12px;

    transition: border-color .2s ease-in-out, background-color .2s ease-in-out;

    border-radius: 4px;
    border: 1px solid esc.color('grey-300');

    &:hover {
        border-color: esc.color('grey-400');
    }

    &:focus-within {
        border-color: esc.color('grey-900');
    }

    &:focus-visible {
        outline: esc.color('info-500') solid 2px;
        outline-offset: 2px;
    }

    &.has-suffix {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &.has-prefix {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    input,
    textarea {
        max-width: 100%;
        min-width: 0;
    }

   &.is-textarea {
       padding: 4px 4px 4px 12px;

       textarea {
           padding: 8px 0;
           @include esc.scrollbar();
           resize: none;
       }
   }

    &.is-select {
        cursor: pointer;
        height: 100%;
    }

    &.is-datepicker {
        .esc-form-field-input-item {
            cursor: default;

            input {
                cursor: default;
            }
        }
    }
}

